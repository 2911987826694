@media screen and (min-width: 1200px) {
	.new-poll-buttons {
		justify-content: space-between;
		padding-left: calc(var(--bs-gutter-x) * 0.5);
		padding-right: calc(var(--bs-gutter-x) * 0.5);
	}
}

@media screen and (max-width: 1199px) {
	.new-poll-buttons {
		justify-content: space-around;
	}
}
