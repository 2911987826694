// Bootstrap Overrided Styling

.card {
	@extend .blurred-light-10;
}

.input-group-text {
	font-size: 1.8rem;
}

.bg-light {
	@extend .blurred-light-10;
}

img.navbar-brand {
	border-radius: 50%;
	height: 40px;
	border: 3px solid teal;
	padding: unset;
}

.navbar-nav {
	flex-direction: row;
	column-gap: 20px;
	--bs-bg-opacity: 0.7;
}

.d-flex {
	flex-direction: row;
}

// .btn {
// 	backdrop-filter: blur('20px');
// }
