// New Classes for styling

.opacity-100 {
	--bs-bg-opacity: 1;
}

.opacity-90 {
	--bs-bg-opacity: 0.9;
}

.opacity-80 {
	--bs-bg-opacity: 0.8;
}

.opacity-70 {
	--bs-bg-opacity: 0.7;
}

.opacity-60 {
	--bs-bg-opacity: 0.6;
}

.opacity-50 {
	--bs-bg-opacity: 0.5;
}

.opacity-40 {
	--bs-bg-opacity: 0.4;
}

.opacity-30 {
	--bs-bg-opacity: 0.3;
}

.opacity-20 {
	--bs-bg-opacity: 0.2;
}

.opacity-10 {
	--bs-bg-opacity: 0.1;
}

.opacity-0 {
	--bs-bg-opacity: 0;
}

// Font weight classes
.font-weight-100 {
	font-weight: 100;
}

.font-weight-200 {
	font-weight: 200;
}

.font-weight-300 {
	font-weight: 300;
}

.font-weight-400 {
	font-weight: 400;
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-600 {
	font-weight: 600;
}

.font-weight-700 {
	font-weight: 700;
}

.font-weight-800 {
	font-weight: 800;
}

.font-weight-900 {
	font-weight: 900;
}

// Hide validation error
.hide-background {
	border: unset;
	background: unset;
}

// Blurred background
.blurred-light-10 {
	background-color: #ffffffaa !important;
	backdrop-filter: var(--blur-10);
}

// Text underline
.text-underline {
	text-decoration: underline;

	:hover {
		color: grey;
	}
}

.btn-login {
	min-width: 100%;
}

.mh-150px {
	min-height: 150px !important;
}

.mh-250px {
	min-height: 250px !important;
}

.w-45-perc {
	width: 45% !important;
}

.hide-sm-under {
	@extend .d-none;
	@extend .d-xl-block;
}

.container-header {
	text-transform: uppercase;
	font-weight: 100;
}

.poll-option {
	text-transform: uppercase;
	font-weight: 300;
	font-size: large;
	transition: all 0.3s ease;

	&:disabled {
		background-color: var(--bs-gray-600) !important;
		color: var(--bs-gray-500);
	}
}
